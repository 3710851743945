.header {
    padding-top: 24px;

    .scrolling-header {
        margin: 0;
        max-width: 100%;
        background-color: rgb(0, 0, 0, .7);
        position: fixed;
        top: 0;
        width: 100%;
        height: 54px;
        z-index: 50;
        box-shadow: 0 0 20px 20px rgb(0, 0, 0, .7);
        transform: translateY(-100px);
        transition: 300ms ease-in;
        text-align: left;
    }

    &.scroll {
        .scrolling-header {
            transform: translateY(0);
        }
    }

    .logo {
        max-width: rem(165px);
        aspect-ratio: 1/1;
        margin-inline-start: rem(80px);
    }

    .logo-secondary {
        max-height: rem(50px);
        aspect-ratio: 1/1;
        margin: rem(16px) 0 0 rem(15px);
    }

    .hamburger-btn {
        position: fixed;
        z-index: 99999;
        border: 0;
        right: rem(15px);
        top: rem(16px);
        padding: 0;
        font-size: rem(40px);
        color: $primary-100;
        -webkit-tap-highlight-color: transparent;
    }

    nav {
        padding-top: rem(74px);
        position: fixed;
        z-index: 9999;
        inset: 0 0 0 30%;
        background-color: rgba(181, 240, 255, 0.1);
        backdrop-filter: blur(1rem);
        transform: translateX(100%);
        transition: transform 350ms ease-in;
    }

    nav[data-visible="true"] {
        transform: translateX(0);

        li {
            transform: translateY(0px);
            opacity: 1;
            transition: opacity 500ms ease-in 500ms, transform 400ms ease-in 400ms;

        }

        @for $i from 1 through 5 {
            li:nth-child(#{$i}) {
                transition-delay: 200ms * $i;
            }
        }
    }

    ul {
        padding-top: 50px;
        width: 50%;
        gap: rem(20px);

        li {
            cursor: pointer;
            transform: translateY(10px);
            opacity: 0;

            &:hover {
                color: $primary-400;
            }
        }
    }

    .button-container {
        padding-top: 50px;

        button {
            color: $primary-100;

            &:hover {
                background-color: $primary-100;

                a {
                    color: $primary-700;
                }
            }
        }
    }

    @media (min-width: $mobile-breakpoint) {
        .hamburger-btn {
            display: none;
        }

        .button-container {
            padding-top: 0;
        }

        nav {
            padding-top: 0;
            position: unset;
            inset: unset;
            background-color: unset;
            width: 100%;
            transform: unset;

            &.flex {
                flex-direction: row;
            }
        }

        ul {
            margin-inline-start: auto;
            padding-top: 0;

            &.flex {
                flex-direction: row;
            }

            li {
                transform: translateY(0);
                opacity: unset;
            }
        }

        .scrolling-header {
            display: none;
        }

    }
}