.about {
    background-color: $primary-700;
    z-index: 3;
    text-align: center;
    padding-block: rem(42px);
    a {
        font-weight: 600;

        &:hover {
            color: $primary-400;
        }
    }

    .info-container {
        a {
            margin: rem(4px);
        }

        .icons-container {
            margin-top: rem(15px);

            .icon {
                font-size: rem(28px);
            }
        }
    }

    .copy {
        opacity: .6;
        padding-top: rem(42px);
        font-size: rem(14px);
    }

    @media (min-width: $narrow-breakpoint) {
        padding-block: rem(80px);
        display: flex;
        justify-content: space-between;

        .copy{
            margin-top: auto;
        }
    }
}