.hero {
    padding-block: rem(80px);
    text-align: center;

    h1 {
        font-size: rem(32px);
        max-width: rem(450px);
        margin-bottom: rem(48px);

        span {
            color: $primary-400;
        }
    }

    hr {
        border-color: $primary-400;
    }

    .logo {
        max-width: rem(108px);
    }

    .paragraph-container {
        max-width: rem(445px);
    }

    p {
        font-size: rem(20px);
        margin-inline-end: rem(48px);
        font-weight: 600;
        margin: 0;
        padding-bottom: rem(24px);
    }

    .profile-img-container {
        text-align: center;

        img {
            border: solid 2px $sercondary-400;
            border-radius: 50%;
            width: rem(165px);
        }
    }

    &>div {
        padding-bottom: rem(140px);
    }

    @media (min-width: $narrow-breakpoint) {
        text-align: start;
        padding-block: rem(60px) rem(89px);

        h1 {
            font-size: rem(52px);
        }

        .info-container {
            flex-direction: row;
            align-items: normal;
        }

        p {
            text-overflow: ellipsis;
            text-align: justify;
        }
    }
}