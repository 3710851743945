.gallery {
    background-color: $sercondary-700;
    padding-top: rem(140px);

    &>* {
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 500ms ease-in, transform 400ms ease-in;
        transition-delay: 200ms;

    }

    &.visible {
        &>* {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    header {
        text-align: center;

        h2 {
            padding-bottom: rem(66px);
        }
    }

    .about-us {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(60px);
        padding-bottom: rem(70px);
        justify-items: center;
        text-align: center;

        @media (min-width: $narrow-breakpoint) {
            grid-template-columns: repeat(2, 1fr);
        }

        .img {
            width: rem(250px);
            height: rem(250px);
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            margin-inline: auto;

            @media (min-width: $narrow-breakpoint) {
                width: rem(350px);
                height: rem(350px);
            }
        }
    }
}

.gallery-carusle {
    position: relative;
    opacity: 0;
    transition: opacity 500ms ease-in;
    transition-delay: 200ms;

    &.visible {
        opacity: 1;
    }

    .carousel {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 100%;
        overflow-x: auto;
        scroll-behavior: contain;
        scroll-snap-type: inline mandatory;

        &::-webkit-scrollbar {
            display: none;
        }

        img {
            width: 100vw;
            aspect-ratio: 6/3;
            object-fit: cover;
            scroll-snap-align: start;
        }

    }

    .next,
    .prev {
        position: absolute;
        color: $primary-100;
        font-size: rem(58px);
        padding: 0;
        margin: 0;
        border: none;
        top: calc(50% - 58px / 2);
    }


    .next {
        right: 0;
    }

    .prev {
        left: 0;

        &>* {
            rotate: 180deg;
        }
    }
}