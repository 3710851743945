// flex helpers
.flex {
    display: flex;

    >*.full {
        flex: 1;
    }

    &.column {
        flex-direction: column;
    }

    &.row {
        flex-direction: row;
    }

    &.row-reverse{
        flex-direction: row-reverse;
    }

    &.justify-center {
        justify-content: center;
    }

    &.align-center {
        align-items: center;
    }

    &.justify-between {
        justify-content: space-between
    }
}

.primary-400 {
    color: $primary-400;
}

.secondary-200 {
    color: $sercondary-200;
}