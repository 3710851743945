@font-face {
    font-family: openSans;
    src: url(../fonts/OpenSans-Regular.ttf);
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: openSans;
    src: url(../fonts/OpenSans-SemiBold.ttf);
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: openSans;
    src: url(../fonts/OpenSans-Bold.ttf);
    font-weight: 700;
    font-display: swap;
}