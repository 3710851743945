.main-layout {
    width: 100%;



    &.full>* {
        max-width: 327px;
        margin: 0 auto;

        @media (min-width: $mobile-breakpoint) {
            max-width: 573px;
        }

        @media (min-width: $narrow-breakpoint) {
            max-width: 1110px;
            padding: 0 18px;
        }
    }

    .container {
        width: 100%;
    }
}