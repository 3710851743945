// Colors
$primary-100: #fffffe;
$primary-400: #FB8500;
$primary-700: #023047;

$sercondary-200: #a7a9be;
$sercondary-400: #ffb703;
$sercondary-500: #8ECAE6;
$sercondary-600: #219EBC;
$sercondary-700: #126782;

$body2-fsize: rem(15px);
$body2-line-height: 1.666666;

$mobile-breakpoint: 768px;
$narrow-breakpoint: 1023px;
$normal-breakpoint: 1280px;