.sub-hero {
    background-color: $sercondary-700;
    padding-block: rem(64px);

    &>* {
        transform: translateY(10px);
        opacity: 0;
        transition: opacity 500ms ease-in, transform 400ms ease-in;
        transition-delay: 200ms;
    }

    &.visible {
        &>* {
            transform: translateY(0);
            opacity: 1;
        }
    }



    @media (min-width: $narrow-breakpoint) {
        padding-top: rem(140px);
        padding-bottom: rem(140px);
    }



    hr {
        border-color: $primary-400;
    }

    .sub-hero-container {
        display: grid;
        gap: rem(14px);
        grid-template-columns: repeat(2, .5fr);

        @media (min-width: $normal-breakpoint) {
            grid-template-columns: repeat(3, 1fr);
        }

        :nth-child(1) {

            grid-column-start: 1;
            grid-column-end: 3;


            @media (min-width: $normal-breakpoint) {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 1;
            }
        }

        :nth-child(2) {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 2;

            @media (min-width: $normal-breakpoint) {
                grid-column-start: 1;
                grid-column-end: 2;
            }
        }

        :nth-child(3) {
            grid-column-start: 2;
            grid-row-start: 3;
            justify-self: center;

            @media (min-width: $normal-breakpoint) {
                grid-column-start: 2;
                grid-row-start: 2;
                grid-row-end: 4;
            }
        }

        :nth-child(4) {
            grid-column-start: 1;
            justify-self: center;

            @media (min-width: $normal-breakpoint) {
                grid-row-start: 1;
                grid-row-end: 3;
                grid-column-start: 3;
            }

        }

        :nth-child(5) {
            display: none;

            @media (min-width: $normal-breakpoint) {
                display: block;
                grid-row-start: 3;
                grid-column-start: 3;
                justify-self: center;
            }
        }
    }

    .certification-txt-container {
        grid-row-start: 1;
        grid-row-end: 3;
    }

    h2 {
        padding: rem(18px) 0;
        text-align: start;
    }

    img:not(.logo) {
        max-width: 100%;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, .25) inset, 0 5px 10px 5px rgba(0, 0, 0, .25);

        @media (min-width: $mobile-breakpoint) {
            max-width: rem(315px);
            max-height: 100%;
        }
    }

    .logo {
        max-width: 150px;
    }
}