.experience {
    padding-block: rem(64px);

    &>* {
        transform: translateY(10px);
        opacity: 0;
        transition: opacity 500ms ease-in, transform 400ms ease-in;
        transition-delay: 200ms;

        &.visible {

            transform: translateY(0);
            opacity: 1;
        }
    }



    &>.flex {
        @media (min-width: $narrow-breakpoint) {
            flex-direction: row;
        }
    }

    .paragraph-container {
        max-width: 27.8125rem;
        padding-bottom: rem(77px);
    }

    h3 {
        padding-bottom: rem(12px);
    }

    p {
        opacity: .8;
        margin: 0;
    }

    ul {
        max-width: rem(540px);
        text-align: center;
    }

    li:not(:last-child) {
        padding-bottom: rem(43px);
    }

    hr {
        margin-bottom: rem(79px);
        border-color: $primary-400;
    }

    @media (min-width: $narrow-breakpoint) {
        padding-block: rem(140px);

        ul {
            text-align: start;
            align-self: start;
        }

        li:not(:last-child) {
            padding-bottom: rem(37px);
        }
    }

}