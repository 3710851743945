.whatsapp {
    cursor: pointer;
    z-index: 1000;
    position: fixed;
    left: 0;
    top: 180px;
    background: #3ebe2b url(../../img/whatsapp.png) right 14px center/40px auto no-repeat;
    width: rem(60px);
    height: rem(60px);
    text-indent: -99999px;
    border-radius: 0 45px 45px 0;

    @media (min-width: $narrow-breakpoint) {
        top: 300px;
        background: #3ebe2b url(../../img/whatsapp.png) right 18px center/48px auto no-repeat;
        width: rem(72px);
        height: rem(72px);
    }
}